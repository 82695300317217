/* eslint-disable jsx-a11y/alt-text */
import "../App.css";
import Logo1 from "../assets/images/gaya_logo.png";
import Logo2 from "../assets/images/logo2.png";
import { Button, Divider } from "antd";
//fixed-top
function NavigationBar({ selected, isMobile }) {
  return (
    <div style={{}}>
      <nav className="navbar navbar-expand-lg navbar-light bg-white ">
        <div
          className="container"
          style={{
            width: "100%",
            borderBottom: "1px solid #E7E7E7",
            // paddingTop: 5,
            paddingBottom: 5,
            paddingRight: 10,
            paddingLeft: 10,
          }}
        >
          <a className="navbar-brand px-2" href="/">
            <img src={Logo1} style={{ width: 100 }} />
            {/* <img src={Logo2} style={{ width: "80px", objectFit: "contain" }} /> */}
          </a>
          <button
            className="navbar-toggler "
            type="button"
            data-toggle="collapse"
            data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
            style={{ height: 55, borderRadius: 100 }}
          >
            <span className="navbar-toggler-icon" />
          </button>

          <div
            className="collapse navbar-collapse  m-3 "
            id="navbarSupportedContent"
            style={{
              backgroundColor: "#ffffff",
              width: "100%",
            }}
          >
            {/* Left side items */}
            <ul className="navbar-nav mr-auto  ">
              <li className="nav-item mt-2">
                <a
                  className="nav-link float-left"
                  href="https://blog.gaya.ai" /* /blog */
                  target="_blank"
                  style={{
                    color: selected === "Blog" ? "#EF5A24" : "black",
                    margin: 10,
                  }}
                >
                  Blog
                </a>
              </li>
              <li className="nav-item mt-2">
                <a
                  className="nav-link float-left"
                  href="/developers"
                  style={{
                    color: selected === "Developers" ? "#EF5A24" : "black",
                    margin: 10,
                  }}
                >
                  Developers
                </a>
              </li>
              <li className="nav-item mt-2">
                <a
                  className="nav-link float-left"
                  href="/careers"
                  style={{
                    color: selected === "Careers" ? "#EF5A24" : "black",
                    margin: 10,
                  }}
                >
                  Careers
                </a>
              </li>
            </ul>
            {/* Right side items */}
            <ul className="navbar-nav ">
              <li className="nav-item d-none d-lg-block" style={{}}>
                <Button
                  style={{
                    // width: "135px",
                    height: "50px",
                    marginRight: 10,
                    borderRadius: 100,
                    paddingLeft: 50,
                    paddingRight: 50,
                    alignItems: "center",
                    justifyContent: "center",
                    borderColor: "#E7E7E7",
                  }}
                  onClick={() => {
                    //window.location = "https://calendly.com/d/cm3-53m-qm4/gaya-team?month=2024-01"
                    window.open("https://portal.gaya.ai/ ", "_blank");
                  }}
                >
                  <a
                    style={{
                      color: "#521184",
                    }}
                  >
                    Login
                  </a>
                </Button>
              </li>
              <li className="nav-item ">
                <Button
                  className="d-none d-lg-block "
                  style={{
                    marginRight: -30,
                    // width: "100%",
                    height: "50px",
                    borderRadius: 100,
                    paddingLeft: 33,
                    paddingRight: 33,
                    // marginRight: !isMobile ? -38 : 0,
                    alignItems: "center",
                    justifyContent: "center",
                    borderColor: "#EF5A24",
                  }}
                  onClick={() => {
                    //window.location = "https://calendly.com/d/cm3-53m-qm4/gaya-team?month=2024-01"
                    window.open(
                      "https://calendly.com/d/cm3-53m-qm4/gaya-team?month=2024-01",
                      "_blank"
                    );
                  }}
                >
                  <a
                    style={{
                      color: "#EF5A24",
                      fontWeight: "bold",
                    }}
                  >
                    GET STARTED
                  </a>
                </Button>
                <Button
                  className="d-lg-none d-sm-block"
                  style={{
                    width: "100%",
                    // marginLeft: "5%",
                    // marginRight: "5%",
                    height: "50px",
                    borderRadius: 100,
                    alignSelf: "center",
                    justifyContent: "center",
                    borderColor: "#EF5A24",
                  }}
                  onClick={() => {
                    //window.location = "https://calendly.com/d/cm3-53m-qm4/gaya-team?month=2024-01"
                    window.open(
                      "https://calendly.com/d/cm3-53m-qm4/gaya-team?month=2024-01",
                      "_blank"
                    );
                  }}
                >
                  <a
                    style={{
                      color: "#EF5A24",
                      fontSize: 20,
                    }}
                  >
                    Get started
                  </a>
                </Button>
              </li>
            </ul>
          </div>
        </div>
      </nav>
      {/* <div
        style={{
          width: "90%",
          alignItems: "center",
          margin: "0 auto",
          // marginBottom: 50,
        }}
      >
        <Divider
          style={{
            borderWidth: 2,
          }}
        />
      </div> */}
    </div>
  );
}

export default NavigationBar;
